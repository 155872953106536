const DisplayAds = {
  display: {
    headlineDelimiter: '|'
  },
  lengthLimits: {
    /*
     * https://support.google.com/google-ads/answer/1704389?hl=en
     *
     * These limits are not always purely character counts as you can have
     * inputs like {keyword:snarf} which only counts as 5 characters. It's
     * the character count of the output that we're concerned with.
     ***********************************************************************/
    headlines: 30,
    headline1: 30,
    headline2: 30,
    headline3: 30,
    longHeadline: 90,
    // description: 90, removing after checking specs for facebook and waze, where this field is used, which do not have a char limit - JC
    descriptions: 90,
    description1: 90,
    description2: 90,
    path1: 15,
    path2: 15,
    businessName: 25,
    siteLinkFinalUrl: null,
    siteLinkLine_2: 35,
    siteLinkLine_3: 35,
    siteLinkText: 25,
    structuredSnippet: 25
  }
}

export default DisplayAds
